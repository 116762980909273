<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap>
                <v-flex xs12>
                    <v-img :src="$imagePath+customer.customer_logo" :alt="customer.customer_name" height="200px"
                        width="200px" contain></v-img>
                    <h1>
                        {{customer.customer_name}}
                    </h1>
                    <p>
                        {{customer.customer_bio}}
                    </p>
                    <p>
                        {{customer.customer_about}}
                    </p>
                    <hr>
                    <h3>
                        Links
                    </h3>
                </v-flex>
                <v-flex xs6 lg2 xl2 sm3 md3 v-for="(item, index) in customer_links" :key="'customer_' + item.link_id + '_' + index">
                    <v-card class="ma-1" outlined>
                        <v-card-title primary-title>
                            <v-img :src="$imagePath+item.link_icon_white" height="70px" width="70px" contain></v-img>
                        </v-card-title>
                        <v-card-text>
                            {{ item.link_name }}
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="info" icon @click="openUpdateDialog(item.customer_link_id)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <!-- create menu for confirmation delete  -->
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Are you sure?</span>
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="deleteLink(item.customer_link_id)">
                                            Yes
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-card-actions>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    Add Links
                </v-flex>
                <v-flex xs6 lg2 xl2 sm3 md3 v-for="(item, index) in checkLinks" :key="'check_' + item.link_id + '_' + index">
                    <v-card class="ma-1" @click="openAddDialog(item)" outlined>
                        <v-card-title primary-title>
                            <v-img :src="$imagePath+item.link_icon_white" height="70px" width="70px" contain></v-img>
                        </v-card-title>
                        <v-card-text>
                            {{ item.link_name }}
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

        </v-container>
        <v-dialog v-model="editDialog" :overlay="false" max-width="500px" transition="fade-transition">
            <v-card>
                <v-card-title primary-title>
                    <span class="headline">Update Link</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field label="Value" v-model="selectedLink.customer_link_value" outlined class="ma-1">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="updateLink">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addDialog" :overlay="false" max-width="500px" transition="fade-transition">
            <v-card>
                <v-card-title primary-title>
                    <span class="headline">Add Link</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="addDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p class="red--text">
                        Phone Number must be in international format (ex: +628123456789) and start with tel:  ex : tel:+628123456789
                    </p>
                    <p class="red--text">
                        Email must be in mailto format (ex: mailto:test@example.com) 
                    </p>

                    <v-text-field label="Value" v-model="newLink.customer_link_value" outlined class="ma-1">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="addNewLink">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import moment from 'moment'
    import requests from './../../requests/customer_links.request'
    export default {
        data() {
            return {
                customer: {},
                customer_links: [],
                links: [],
                newLink: {},
                selectedLink: {},
                editDialog: false,
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                addDialog: false,

            }
        },
        computed: {
            checkLinks() {
                const customerLinks = this.customer_links.map(item => item.link_id)
                const otherLinks = this.links

                // return links that not in customer_links
                return otherLinks.filter(item => !customerLinks.includes(item.link_id))

            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getOneCustomers()
        },
        methods: {
            getOneCustomers() {
                this.loading = true
                requests.getOneCustomerLinks(this.id).then(r => {
                        if (r.status == 200) {
                            this.customer = r.data.customer
                            this.customer_links = r.data.customer_links
                            this.links = r.data.links


                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Customers',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Customers',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            deleteLink(customer_link_id) {
                this.loading = true
                requests.deleteCustomerLinks(customer_link_id).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'Link Deleted',
                                color: 'success'
                            }
                            this.getOneCustomers()
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to delete Link',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to delete Link',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            addNewLink() {
                this.loading = true
                requests.createCustomerLinks(this.newLink).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'Link Added',
                                color: 'success'
                            }
                            this.getOneCustomers()
                            this.addDialog = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to add Link',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Link',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            updateLink() {
                this.loading = true
                requests.updateCustomerLinks(this.selectedLink.customer_link_id, this.selectedLink).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'Link Updated',
                                color: 'success'
                            }
                            this.getOneCustomers()
                            this.editDialog = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to update Link',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to update Link',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            openUpdateDialog(customer_link_id) {
                const link = this.customer_links.find(item => item.customer_link_id == customer_link_id)
                this.selectedLink = {
                    customer_link_id: link.customer_link_id,
                    customer_link_value: link.customer_link_value,
                    customer_id: link.customer_id,
                    link_id: link.link_id
                }
                this.editDialog = true
            },
            openAddDialog(item) {
                this.newLink = {
                    customer_id: this.id,
                    link_id: item.link_id,
                    customer_link_value: ''
                }
                this.addDialog = true
            },
        },
    }
</script>